import { Link } from 'gatsby';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import './__styles__/logo.module.css';

const Logo = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            localityName
          }
        }
      }
    `}
    render={data => (
      <Link to="/" styleName="logo" aria-label="homepage link">
        {data.site.siteMetadata.localityName}
        <br />
        <span styleName="subtitle">Flood Information Center</span>
      </Link>
    )}
  />
);

export default Logo;
