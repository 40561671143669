import React from 'react';
import PropTypes from 'prop-types';

import Container from './container';
import './__styles__/pageHeader.module.css';

const PageHeader = ({ title, subtitle }) => (
  <header styleName="header">
    <Container>
      <h1 styleName="title">{title}</h1>
      {!!subtitle && <p styleName="subtitle">{subtitle}</p>}
    </Container>
  </header>
);

PageHeader.defaultProps = {
  subtitle: null,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default PageHeader;
