import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';
import cx from 'classnames';

import ContactButton from './contactButton';
import Logo from './logo';
import Container from './container';
import './__styles__/header.module.css';
import { useMediaQuery } from '../utils';

const Header = ({ data }) => {
  const isMobile = useMediaQuery(0, 767);
  const subnavRef = React.useRef(null);
  const [isNavOpen, toggleNav] = React.useState(null);
  const [isSubnavOpen, toggleSubnav] = React.useState(null);

  const handleOutsideClick = evt => {
    if (isMobile) return;
    if (subnavRef.current && !subnavRef.current.contains(evt.target)) {
      toggleSubnav(false);
    }
  };

  const handleSubnavToggle = evt => {
    evt.preventDefault();
    toggleSubnav(!isSubnavOpen);
  };

  const handleSubnavBlur = evt => {
    window.setTimeout(() => {
      if (
        subnavRef.current &&
        !subnavRef.current.contains(document.activeElement)
      ) {
        toggleSubnav(false);
      }
    }, 0);
  };

  const handleNavToggle = evt => {
    evt.preventDefault();
    toggleNav(!isNavOpen);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    toggleNav(!isMobile);
    toggleSubnav(isMobile);
  }, [isMobile]);

  return (
    <header styleName="header" role="banner">
      <Container styleName="container">
        <div styleName="logo">
          <Logo />
        </div>
        <nav styleName="nav" aria-label="main navigation">
          {isMobile && (
            <button
              onClick={handleNavToggle}
              styleName="menu-button"
              aria-controls="main-nav"
              aria-expanded={isNavOpen}
            >
              Menu
            </button>
          )}
          <ul id="main-nav" styleName={cx('nav-list', { hide: !isNavOpen })}>
            <li
              styleName="nav-list-item has-subnav"
              ref={subnavRef}
              onMouseEnter={() => toggleSubnav(true)}
              onMouseLeave={() => toggleSubnav(false)}
            >
              <a
                href="/"
                styleName="nav-list-item-link"
                onFocus={() => toggleSubnav(true)}
                onTouchEnd={handleSubnavToggle}
              >
                Flood Information
              </a>
              <ul styleName={cx('nav-list', 'subnav', { show: isSubnavOpen })}>
                {data.markdownRemark.frontmatter.flood_info_resources.map(
                  link => (
                    <li styleName="nav-list-item" key={link.page}>
                      <Link
                        to={`/${link.page}`}
                        styleName="nav-list-item-link"
                        onBlur={handleSubnavBlur}
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </li>
            <li styleName="nav-list-item">
              <Link to="/elevation-certificates" styleName="nav-list-item-link">
                Elevation Certificates
              </Link>
            </li>
            <li styleName="nav-list-item">
              <Link to="/flood-gauge-info" styleName="nav-list-item-link">
                Flood Gauge Info
              </Link>
            </li>
            <li styleName="nav-list-item">
              <ContactButton styleName="get-help-button" />
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { template: { eq: "index-page" } }) {
          frontmatter {
            flood_info_resources {
              title
              page
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
