import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';

import Logo from './logo';
import Container from './container';
import './__styles__/footer.module.css';

const Footer = ({ data }) => (
  <footer styleName="footer" role="contentinfo">
    <Container styleName="container">
      <Logo />
      <nav styleName="nav" role="navigation">
        <ul styleName="nav-list">
          {data.markdownRemark.frontmatter.flood_info_resources.map(link => (
            <li styleName="nav-list-item" key={link.page}>
              <Link to={`/${link.page}`}>{link.title}</Link>
            </li>
          ))}
        </ul>
        <ul styleName="nav-list">
          <li styleName="nav-list-item">
            <Link to="/elevation-certificates">Elevation Certificates</Link>
          </li>
          <li styleName="nav-list-item">
            <Link to="/flood-gauge-info">Flood Gauge Info</Link>
          </li>
          <li styleName="nav-list-item">
            <Link to="/contact">Get Help</Link>
          </li>
        </ul>
      </nav>
      <div styleName="credits">
        <a
          href="https://www.withforerunner.com/"
          styleName="powered-by"
          target="_blank"
          rel="noopener noreferrer"
        >
          Powered by Forerunner
        </a>
        <p>
          Forerunner builds intelligent tools to help communities adapt to
          extreme weather
        </p>
      </div>
    </Container>
  </footer>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { template: { eq: "index-page" } }) {
          frontmatter {
            flood_info_resources {
              title
              page
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
