import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './__styles__/layout.module.css';

const BackLink = ({ className, backTo }) => (
  <Link styleName="back-link" className={className} to={backTo[1]}>
    &larr; Back to {backTo[0]}
  </Link>
);

BackLink.defaultProps = {
  className: null,
  backTo: ['Homepage', '/'],
};

BackLink.propTypes = {
  className: PropTypes.string,
  backTo: PropTypes.array,
};

export default BackLink;
