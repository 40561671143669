import React from 'react';
import { Link } from 'gatsby';

import Container from './container';
import './__styles__/getHelpSection.module.css';

const defaultTile = 'Have questions?';
const defaultContent = (
  <p>
    If you would like to learn more about topics like flood insurance, local
    flood hazards, or historic floods, we’re here to help. Reach out to speak
    with your local floodplain expert.
  </p>
);

const getHelpSection = ({ title = defaultTile, children = defaultContent }) => (
  <div styleName="section">
    <Container styleName="container">
      <div styleName="content">
        <h3 styleName="header">{title}</h3>
        {children}
        <Link to="/contact" styleName="contact-button">
          Request Help
        </Link>
      </div>
    </Container>
  </div>
);

export default getHelpSection;
