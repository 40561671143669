import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './__styles__/contactButton.module.css';

const Icon = () => (
  <svg
    styleName="icon-svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <path
      d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z"
      fill="#000"
    />
    <path
      d="M8 14.5C11.866 14.5 15 11.366 15 7.5C15 3.63401 11.866 0.5 8 0.5C4.13401 0.5 1 3.63401 1 7.5C1 11.366 4.13401 14.5 8 14.5Z"
      stroke="#000"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.5V7.5"
      stroke="#000"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ContactButton = ({ className }) => (
  <Link to="/contact" styleName="button" className={className}>
    <Icon />
    Get Help
  </Link>
);

ContactButton.defaultProps = {
  className: '',
};

ContactButton.propTypes = {
  className: PropTypes.string,
};

export default ContactButton;
