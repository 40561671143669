import React from 'react';

import BackLink from './backLink';
import './__styles__/layout.module.css';

const Container = ({ className, children }) => (
  <div styleName="container" className={className}>
    {children}
  </div>
);

export default Container;

export const ContentContainer = ({ className, children }) => (
  <div styleName="content-container" className={className}>
    {children}
  </div>
);

export const Sidebar = ({ className, backTo, children }) => (
  <div styleName="sidebar" className={className}>
    {children}
    <div styleName="sidebar-footer">
      <BackLink backTo={backTo} />
    </div>
  </div>
);

export const Main = ({ className, children }) => (
  <article styleName="main" className={className}>
    {children}
  </article>
);
