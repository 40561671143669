import React from 'react';
import { Link, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import _ from 'lodash';

import Layout from '../components/layout';
import GetHelpSection from '../components/getHelpSection';
import PageHeader from '../components/pageHeader';
import SEO from '../components/seo';
import Container from '../components/container';

import './__styles__/home.module.css';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Homepage" />
    <PageHeader
      title={`Welcome to ${data.site.siteMetadata.localityNameShort}’s
      Flood Information Center`}
    />
    <Container>
      <div styleName="section">
        <header styleName="header">
          <h2 styleName="section-title">Flood Information</h2>
          <span>Information about flood hazards within our community.</span>
        </header>
        <div styleName="grid-container">
          {data.markdownRemark.frontmatter.flood_info_resources.map(
            resource => (
              <div styleName="grid-item" key={resource.title}>
                <div
                  styleName="info-item"
                  style={{
                    backgroundImage: `url(${resource.icon})`,
                  }}
                >
                  <h3 styleName="title">{resource.title}</h3>
                  <p>{resource.summary}</p>
                  <div styleName="cta">
                    <Link
                      to={`/${resource.page}/`}
                      styleName="link"
                      aria-label={resource.cta}
                    >
                      {resource.cta}
                    </Link>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div styleName="section">
        <header styleName="header">
          <h2 styleName="section-title">Helpful Resources</h2>
          <span>Resources to help evaluate your risk.</span>
        </header>
        <div styleName="grid-container">
          <div styleName="resource-item-thumb grid-item certificates">
            <h3 styleName="title">Elevation Certificates</h3>
            <p>All of our Elevation Certificates are on file to view.</p>
            <div styleName="cta">
              <Link to="/elevation-certificates" styleName="link">
                View certificates
              </Link>
            </div>
          </div>
          <div styleName="resource-item-thumb grid-item gauge">
            <h3 styleName="title">Flood Gauge</h3>
            <p>Learn about our community's current water conditions.</p>
            <div styleName="cta">
              <Link to="/flood-gauge-info" styleName="link">
                View flood gauge information
              </Link>
            </div>
          </div>
        </div>
        <h2 styleName="section-title">Additional Links</h2>
        <div styleName="grid-container">
          {data.markdownRemark.frontmatter.external_links.map(link => (
            <div styleName="resource-item grid-item" key={link.title}>
              <div styleName="link-wrapper">
                <OutboundLink href={link.url} styleName="link">
                  {link.title}
                </OutboundLink>
              </div>
              <p>{link.summary}</p>
            </div>
          ))}
          {_.times(
            3 - (data.markdownRemark.frontmatter.external_links.length % 3),
            n => (
              <div styleName="grid-item" key={`blank-${n}`} />
            )
          )}
        </div>
      </div>
    </Container>
    <GetHelpSection />
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        flood_info_resources {
          cta
          icon
          page
          summary
          title
        }
        external_links {
          summary
          title
          url
        }
      }
    }
    site {
      siteMetadata {
        localityNameShort
        localityName
      }
    }
  }
`;

export default IndexPage;
