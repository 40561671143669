import React from 'react';

export function useMediaQuery(lowerBound, upperBound) {
  if (typeof window === 'undefined') return false;

  const [matchesQuery, setMatchesQuery] = React.useState(isInBound());

  function isInBound() {
    return lowerBound <= window.innerWidth && window.innerWidth <= upperBound;
  }

  function handleResize() {
    setMatchesQuery(isInBound());
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return matchesQuery;
}
